<template>
  <div class="AccountBody">
    <el-container>
        <el-header class="Header">
             <el-row justify="space-between">
          <el-col :span="2">
            <div class="Index" @click="ToIndex">首页</div>
          </el-col>
          <el-col :span="2">
            <div class="Active" @click="ToEvents">活动</div>
          </el-col>
          <el-col :span="2">
            <div class="Square" @click="ToZone">广场</div>
          </el-col>
          <el-col :span="2">
            <div class="QA" @click="ToMainQA">Q&A</div>
          </el-col>
          <el-col :span="4"><div class=""></div></el-col>
        </el-row>
        </el-header>
        <el-container>
            <el-aside :width=asidewidth class="Aside">
                <el-menu default-active="/account/usercenter" class="el-menu-vertical-demo" @select="HandlePageRouter" :collapse="isCollapse">
                    <el-menu-item index="/account/usercenter">
                        <i class="el-icon-menu"></i>
                        <span slot="title" style="color:white;">个人中心</span>
                    </el-menu-item>
                    <el-menu-item index="/account/activitygoal">
                        <i class="el-icon-document"></i>
                        <span slot="title">目标管理</span>
                    </el-menu-item>
                    <el-menu-item index="/account/editingcenter">
                        <i class="el-icon-edit"></i>
                        <span slot="title">创作中心</span>
                    </el-menu-item>
                    <el-menu-item index="/account/setting">
                        <i class="el-icon-setting"></i>
                        <span slot="title">我的信息</span>
                    </el-menu-item>
                </el-menu>            
            </el-aside>
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
  </div>
</template>

<script>
import '../assets/rem.js'
export default {
    name:'Account',
    data(){
        return{
            asidewidth:'200px',
            isCollapse:false
        }
    },
    created(){

    },
    mounted(){
        
    },
    methods:{
        handleOpen(){

        },
        handleClose(){

        },
        HandlePageRouter(index){
            this.$router.push({
                path:index
            })
        },
        ToIndex(){
            this.$router.push({
                path:'/index'
            })
        },
        ToEvents(){
            this.$router.push({path:'/events'})
        },
        ToZone(){
            this.$router.push({path:'/zone'})
        },
        ToMainQA(){
            this.$router.push({path:'/mainqa'})
        }
    }
}
</script>

<style scoped>
.Header{
    line-height: 0.6rem;
    width: 100%;
    box-shadow: 0px 5px 50px 0px rgb(0 0 0 / 10%);
    position: sticky;
    top: 0;
    background: #ffffff;
    padding: 0 !important;
    z-index: 20;
}
.Index , .Square , .QA ,.Active{
  height: 100%;
  font-size: 0.16rem;
  cursor: pointer;
}
.Index:hover ,.Active:hover, .Square:hover , .QA:hover {
  background: #dddddd;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 190px;
    min-height: 400px;
}
.Header{
    font-size: 0.3rem;
    padding: 0.2rem 0;
}
.Aside{
    height: 100%;
}
.el-menu-item{
    user-select: none;
}
</style>